<template>
  <card :title="$t('sermons.info')">
    <form @submit.prevent="create" @keydown="form.onKeydown($event)">
      <alert-success :form="form" :message="$t('sermons.created')" />

      <sermon-form :form="form"></sermon-form>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <v-button :loading="form.busy" type="success">
            {{ $t('create') }}
          </v-button>
        </div>
      </div>
    </form>
  </card>
</template>

<script>
  import Form from "vform"
  import SermonForm from "./form"

  export default {
    scrollToTop: false,
    components: {
      'sermon-form': SermonForm
    },
    data: () => ({
      form: new Form({
        videoUrl: '',
        thumbnailUrl: '',
        thumbnail: null,
        title: '',
        date: '',
        description: '',
        verses: '',
        topics: [],
        speaker_id: '',
      })
    }),

    methods: {
      create() {
        this.$store.dispatch('account/createSermon', { form: this.form }).then(({ sermon }) => {
          this.$router.push({ name: 'accounts.sermons.edit', params: {id: sermon.id }});   
        });        
      }
    }
  }
</script>
